.reset-password-container {
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: auto;
  overflow: hidden;
  transition: max-height 0.3s;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  color: white;
  p,
  h3 {
    margin-bottom: 0;
  }
}
