.home-card-header {
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.7px;
  font-family: 'Oswald';
  font-weight: 500;
  text-transform: uppercase;
  &.go-back {
    display: flex;
    justify-items: center;
    gap: 30px;
    align-items: center;
    cursor: pointer;
    width: 100%;
  }
}
