@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.login-card {
  width: 100%;
  height: 100%;
  padding: 48px;
  padding-bottom: 32px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.mfa-input-container {
  display: flex;
  gap: 8px;
}

@include media-breakpoint-down(xs) {
  .login-card {
    padding: 32px 16px 24px;
  }
}
