@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.app-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .app-tm-logo {
    max-width: 100%;
    padding: 100px 15px 16px;
    flex-shrink: 1;
  }

  .app-tm-footer {
    color: #989898;
    text-align: center;
    font-size: 11px;
    line-height: 1;
    padding-bottom: 32px;
    padding-top: 81px;
    height: 124px;
    display: flex;
    gap: 42px;
    .app-tm-footer-link {
      cursor: pointer;
      .lg-view {
        display: inline-block;
      }
      .sm-view {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .app-container {
    .app-tm-logo {
      padding: 32px 15px 16px;
      height: 66px;
    }
    .app-tm-footer {
      padding: 16px 15px;
      height: 43px;
      gap: 36px;
      .app-tm-footer-link {
        .lg-view {
          display: none;
        }
        .sm-view {
          display: inline-block;
        }
      }
    }
  }
}

.app-scroll-container,
#root,
body,
html {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.app-scroll-container {
  overflow-y: scroll;

  /* Hide scrollbar for content fit */
  /* Firefox */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    /* Chrome, Safari, Edge */
    display: none;
  }
}
