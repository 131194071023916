.tabbar-container {
  display: flex;
  align-items: center;
  position: relative;
  gap: 16px;
  .tabbar-item {
    padding: 4px;
    text-decoration: none;
    width: fit-content;
    color: #a0a0a0;
    font-size: 22px;
    text-align: center;
    letter-spacing: 0.7px;
    font-family: 'Oswald';
    font-weight: 500;
    border-bottom: 2px solid transparent;
    transition: border-bottom 150ms linear, color 150ms linear;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
    &:hover {
      color: #ec691a;
    }
    &.user-is-here {
      color: #ec691a;
      border-bottom: 2px solid #ec691a;
    }
  }
}
