.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: auto;
  .home-cards-container {
    width: 100%;
    overflow: hidden;
    transition: max-height 0.3s;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    display: flex;
    color: white;
    .home-card {
      width: 100%;
      height: 100%;
      flex-shrink: 0;
      transition: transform 0.3s, opacity 0.3s;
    }
  }
  .checkbox-container {
    .MuiFormControlLabel-root {
      justify-content: start;
    }
  }
}
